@font-face {
    font-family: 'Apercu';
    src: url('../font/apercu/Apercu-Medium.eot');
    src: url('../font/apercu/Apercu-Medium.eot?#iefix') format('embedded-opentype'),
        url('../font/apercu/Apercu-Medium.woff2') format('woff2'),
        url('../font/apercu/Apercu-Medium.woff') format('woff'),
        url('../font/apercu/Apercu-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apercu';
    src: url('../font/apercu/Apercu-Medium_1.eot');
    src: url('../font/apercu/Apercu-Medium_1.eot?#iefix') format('embedded-opentype'),
        url('../font/apercu/Apercu-Medium_1.woff2') format('woff2'),
        url('../font/apercu/Apercu-Medium_1.woff') format('woff'),
        url('../font/apercu/Apercu-Medium_1.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apercu';
    src: url('../font/apercu/Apercu-Medium_2.eot');
    src: url('../font/apercu/Apercu-Medium_2.eot?#iefix') format('embedded-opentype'),
        url('../font/apercu/Apercu-Medium_2.woff2') format('woff2'),
        url('../font/apercu/Apercu-Medium_2.woff') format('woff'),
        url('../font/apercu/Apercu-Medium_2.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apercu';
    src: url('../font/apercu/Apercu-Regular.eot');
    src: url('../font/apercu/Apercu-Regular.eot?#iefix') format('embedded-opentype'),
        url('../font/apercu/Apercu-Regular.woff2') format('woff2'),
        url('../font/apercu/Apercu-Regular.woff') format('woff'),
        url('../font/apercu/Apercu-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apercu';
    src: url('../font/apercu/Apercu-Regular_1.eot');
    src: url('../font/apercu/Apercu-Regular_1.eot?#iefix') format('embedded-opentype'),
        url('../font/apercu/Apercu-Regular_1.woff2') format('woff2'),
        url('../font/apercu/Apercu-Regular_1.woff') format('woff'),
        url('../font/apercu/Apercu-Regular_1.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apercu';
    src: url('../font/apercu/Apercu-Regular_2.eot');
    src: url('../font/apercu/Apercu-Regular_2.eot?#iefix') format('embedded-opentype'),
        url('../font/apercu/Apercu-Regular_2.woff2') format('woff2'),
        url('../font/apercu/Apercu-Regular_2.woff') format('woff'),
        url('../font/apercu/Apercu-Regular_2.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

