.dd-menu > li > a {
  text-decoration: none;
  color: white;
}

.dd-menu > li > a:hover {
  color: white;
}

/* Dropdown */

.dropdown {
  display: inline-block;
  position: relative;
}

.dd-button {
  display: inline-block;
  border: 2px solid gray;
  border-radius: 4px;
  padding: 5px 20px 5px 15px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 14px;
}

.dd-button:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
}

.dd-button:hover {
}

.dd-input {
  display: none;
}

.dd-menu {
  position: absolute;
  top: 100%;
  border: 2px solid white;
  border-radius: 4px;
  padding: 0;
  margin: 2px 0 0 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #516946;
  list-style-type: none;
  z-index: 2;
  width: 100%;
  font-size: 14px;
}

.dd-input + .dd-menu {
  display: none;
}

.dd-input:checked + .dd-menu {
  display: block;
}

.dd-menu li {
  padding: 8px 16px;
  cursor: pointer;
  white-space: nowrap;
}

.dd-menu li:hover {
}

.dd-menu li a {
  display: block;
  margin: -10px -20px;
  padding: 10px 20px;
}

.dd-menu li.divider {
  padding: 0;
  border-bottom: 1px solid #cccccc;
}
