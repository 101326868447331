/* @media only screen and (max-width: 980px) {
} */

@media only screen and (max-width: 980px) {
  .main .home section{ min-height: inherit; padding-bottom: 20%; padding-top: 20% !important;}
  .main .home2 section{ min-height: inherit; padding-bottom: 20%; padding-top: 20% !important;}

  .container{width: 95%;}
  /* introduce */
  .introduce .intoduceIns{flex-direction: column; margin-top: 12%;}
  .introduce .intoduceIns .art_landing{width: 80%; margin-top: 20px;}
  .introduce .intoduceIns .art_landing.art_left{display: none;}
  .introduce .intoduceIns .logo_landing{width: 90%;}
  .introduce .intoduceIns .logo_landing img{width: 80%; margin: 0 auto}
  .introduce .intoduceIns .logo_landing .text_landing{margin-bottom: 0;}
  .introduce .intoduceIns .logo_landing .btn-mint{margin-top: 30px;}
  .introduce .intoduceIns .art_landing .wave{display: none;}
  .introduce .intoduceIns .art_landing .mountain{display: none;}

  
  /* about */
  .about .about_text span{width: 90%; font-size: 14px;}
  .about ul.listTeam{width: 400%;}
  
  /* roadmap */
  .roadmap .roadmapIns{flex-direction: column;}
  .roadmap .roadmapIns .listPhase.left{width: 100%;}
  .roadmap .roadmapIns .listPhase.right{justify-content: center;}
  .roadmap .roadmapIns .listPhase .phase{display: flex; flex-direction: row; align-items: center; justify-content: space-between;}
  .roadmap .roadmapIns .listPhase .phase1{margin-bottom: 0;}
  .roadmap .roadmapIns .listPhase .phase2{text-align: left !important; margin: 40px 0;}
  .roadmap .roadmapIns .listPhase .phase3{text-align: center !important; flex-direction: column;}
  .roadmap .roadmapIns .listPhase .phase .img_mobile{width: 40%;}
  .roadmap .roadmapIns .listPhase .phase3 .img_mobile{width: 80%; margin-top: 20px;}
  .roadmap .roadmapIns .img{display: none;}
  .roadmap .roadmapIns .listPhase .phase .desc {font-size: 12px;}
  
  /* utility */
  .utility .title .txt{font-size: 26px;}
  .utility .title .ic{width: 50px;}
  .creator_space .content{flex-direction: column;}
  .creator_space .content .list{width: 100%;}
  .creator_space .content .list .img::after{display: none;}
  
  /* fuzzClan */
  .fuzzClan{height: auto; border-bottom: 0;}
  .fuzzClan .title{font-size: 25px; margin-bottom: 40px;}
  .fuzzClan .listMemmber{flex-flow: row wrap; width: 100%;}
  .fuzzClan .listMemmber ul{flex: 0 0 50%; margin-bottom: 20px;bottom: 0;}
  .fuzzClan .listMemmber ul:hover{bottom: 0;}
  .fuzzClan .listMemmber ul:nth-child(7), 
  .fuzzClan .listMemmber ul:nth-child(8){margin-bottom: 0;}
  .fuzzClan .listMemmber .name{font-size: 16px;}
  .fuzzClan .listMemmber .role{font-size: 12px;}

  .App .network{top: 63px;}
  .App .network .ic_close{display: none;}
  
  .footer .container{display: block; text-align: center; font-size: 12px;}
  

  .introduceStack .info-btn{margin-top: 40px;}
  .introduceStack .info-btn .info{text-align: center;}
  .introduceStack .introduceStackIns{flex-direction: column;}
  .introduceStack .introduceStackIns .stack-ins{border-right: 0; width: 100%;}
  .introduceStack .introduceStackIns .stack-ins:first-child{ margin-bottom: 70px;}
  .introduceStack .info-btn .btn button{margin: 0 20px}

  .foxpandaimg{max-width: 200px;}
  .bg-garis-x{background: url('../img/web/v5/bgx.png') top center no-repeat; background-size: cover;}
  .playimg{max-width: 90px; cursor: pointer;}

  /* Astro About */
  .bg-about {display: flex; align-items: center; justify-content: center;}
  .bg-about .img-about {height: 50%;}
  .about2{display: flex; flex-direction: column; justify-content:start; align-items: start; margin-top: 98px; height: 100vh;}
  .about2 .aboutIns{ justify-content: center; align-items: center;}
  .about2 .aboutIns .imgAbout{ width:70%; align-items: center; display: flex; margin-right: 80px; margin-top: 70px;}
  .about2 .about_text{display: flex;  flex-direction: column; margin-left: 10px; height: 40%; padding-bottom: 20px;}
  .about2 .about_text .bold{font-weight:900; font-size: 20px; margin: 20px 0;  }
  .about2 .about_text .bold2{font-weight:900; font-size: 15px; margin: 20px 0;  }
  .about2 .about_text .text2{font-weight:100; font-size: 12px; margin-bottom: 14px;margin: 20px 0;  }
  .about2 .text .about_text .bold{font-weight:900; font-size: 15px;}
  .about2 .text .about_text .bold span{width: 600px; font-size: 25px;}
  .about2 .text .about_text .bold .desc{font-size: 12px; margin-top: 1%;}
  .about2 .close{display: flex; justify-content: center; align-items: center; width: 100%;}
  .about2 .about_text span{width: 90%; font-size: 14px;}

  /* Astro Roadmap */
  .roadmap2 {display: flex; overflow:hidden; flex-direction: column; justify-content:start; align-items: start; margin-top: 98px; height: 100vh;}
  .roadmap2 .title {margin-top: 20%; font-size: 25px;}
  .roadmap2 .content .imgRoadmap {display: flex; margin-top: 25%; width: 100%; }
  .roadmap2 .div-phase1 {display: none;}
  .roadmap2 .div-phase2 {display: none;}
  .roadmap2 .div-phase3 {display: none;}
  .roadmap2 .phase1 {color: #fff; position: absolute; top: 70%; left: 8%; width: 90px;}
  .roadmap2 .phase2 {color: #fff; position: absolute; top: 70%; left: 45%; width:90px; } 
  .roadmap2 .phase3 {color: #fff; position: absolute; top: 70%; left: 75%; width:90px; } 


  /* Astro Utility */
  .utility2{margin-top: 10%; height: 100%;}
  .utility2 .title .txt{font-size: 26px;}
  .utility2 .title .ic{width: 50px;}

  /* Astro Team */
  .fuzzClan2{display: flex; flex-direction: column; justify-content:start; align-items: start; margin-top: 98px; height: 100vh;}
  .fuzzClan2 .title{font-size: 25px; margin-top: 60px; margin-bottom: 30px;}
  .fuzzClan2 .img{width: 100%;}
  .fuzzClan2 .content .imgClan {width: 100%; position: relative; display: flex;}

  .content-hide {position:absolute; display:block; transition: 0.2s all; opacity: 1; pointer-events: auto; z-index: 3; min-height: 100vh;}
  .content-hide .close{position:block; display:block; bottom: 1%; margin-left: 80%; justify-content: end; align-items: end; transition: 0.2s all; opacity: 1; pointer-events: auto; z-index: 3;}
  .hide-content {opacity: 0; pointer-events: none; z-index: -1;}
  .close {position:absolute; display:block; margin-top: 50%; bottom: 0; left: 0; right: 0; transition: 0.2s all; opacity: 1; pointer-events: auto; z-index: 3;}

  .content-hide2 {position:absolute; display:block; top: 0; bottom: 0; left: 0; right: 0; transition: 0.2s all; opacity: 1; pointer-events: auto; z-index: 3; min-height: 100vh;}
  .content-hide2 .close2{position:absolute; display:block;  margin-bottom: 1%; margin-left: 45%; right: 0; transition: 0.2s all; opacity: 1; pointer-events: auto; z-index: 3;}
  .hide-content2 {opacity: 0; pointer-events: none; z-index: -1;}
  .close2 {position:absolute; display:block; margin-top: 50%; bottom: 0; left: 0; right: 0; transition: 0.2s all; opacity: 1; pointer-events: auto; z-index: 3;}
}
