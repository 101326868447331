.button-19 {
  appearance: button;
  /* background: linear-gradient(180deg, #eea02e 0%, #f9bf40 100%); */
  border: solid transparent;
  /* border-radius: 16px; */
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  /* font-family: din-round, sans-serif; */
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 5px 16px;
  /* text-align: center; */
  /* text-transform: uppercase; */
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter 0.2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  /* width: 100%; */
}

.button-19:after {
  background-clip: padding-box;
  /* background-color: #1cb0f6; */
  /* background: linear-gradient(180deg, #eea02e 0%, #f9bf40 100%); */
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.button-19:main,
.button-19:focus {
  user-select: auto;
}

.button-19:hover:not(:disabled) {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.button-19:disabled {
  cursor: auto;
}
