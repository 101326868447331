@font-face {
    font-family: 'Acumin Pro';
    src: url('../font/acumin/AcuminPro-MediumItalic.eot');
    src: url('../font/acumin/AcuminPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../font/acumin/AcuminPro-MediumItalic.woff2') format('woff2'),
        url('../font/acumin/AcuminPro-MediumItalic.woff') format('woff'),
        url('../font/acumin/AcuminPro-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: url('../font/acumin/AcuminPro-SemiboldItalic.eot');
    src: url('../font/acumin/AcuminPro-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('../font/acumin/AcuminPro-SemiboldItalic.woff2') format('woff2'),
        url('../font/acumin/AcuminPro-SemiboldItalic.woff') format('woff'),
        url('../font/acumin/AcuminPro-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: url('../font/acumin/AcuminPro-Medium.eot');
    src: url('../font/acumin/AcuminPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('../font/acumin/AcuminPro-Medium.woff2') format('woff2'),
        url('../font/acumin/AcuminPro-Medium.woff') format('woff'),
        url('../font/acumin/AcuminPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: url('../font/acumin/AcuminPro-Semibold.eot');
    src: url('../font/acumin/AcuminPro-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../font/acumin/AcuminPro-Semibold.woff2') format('woff2'),
        url('../font/acumin/AcuminPro-Semibold.woff') format('woff'),
        url('../font/acumin/AcuminPro-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}