@font-face {
    font-family: 'Montserrat';
    src: url('../font/montserrat/Montserrat-Regular.ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../font/montserrat/Montserrat-Bold.ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/* @font-face {
    font-family: 'Montserrat';
    src: url('../font/montserrat/Montserrat-Light.ttf');
    font-weight: lighter;
    font-style: normal;
    font-display: swap;
} */

@font-face {
    font-family: 'Montserrat';
    src: url('../font/montserrat/Montserrat-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/* @font-face {
    font-family: 'Montserrat';
    src: url('../font/montserrat/Montserrat-Thin.ttf');
    font-weight: thin;
    font-style: normal;
    font-display: swap;
} */

