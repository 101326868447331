html { scroll-behavior: smooth; }
body{ margin: 0; font-family: 'Montserrat'!important; background: #fff; color: #414042; font-size: 16px; overflow-x: hidden;}
img{width: 100%; display: block;}
a{text-decoration: none; color: #fff;}
a:hover{color: #fff;}
ul{margin: 0; padding: 0}
li{list-style: none;}
section{ min-height: 100vh; }
.mobile{display: none !important;}
.desktop{display: block !important;}
.loading{display: flex; justify-content: center; align-items: center; background: #000000; background-repeat: repeat-x; min-height: 100vh;}

.css-1o6z5ng {color: white!important}
/* introduce */
.introduce{display: flex; justify-content: center; align-items: center; background: url('../img//web/bg-home.png') bottom center; background-repeat: repeat-x;}
.bg-about{background: url('../img/bg-aboutus.jpg') center center no-repeat; background-size:cover;}
.bg-roadmap{background: url('../img/bg-roadmap.jpg') center center no-repeat; background-size: 100%;}
.bg-utility{background: url('../img/bg-utility.jpg') center center no-repeat; background-size: cover;}
.bg-rarity{background: url('../img/bg-rarity.jpg') center center no-repeat; background-size: 100%;}
.introduce .intoduceIns{display: flex; align-items: center; justify-content: space-between;}
.introduce .intoduceIns .art_landing{width: 30%; position: relative;}
.introduce .intoduceIns .art_landing .img_art{position: relative;}
.introduce .intoduceIns .art_landing .mountain{ position: absolute; bottom: -90px; right: -50px; display: flex; justify-content: flex-end;}
.introduce .intoduceIns .art_landing .mountain img{width: 80%;}
.introduce .intoduceIns .art_landing .wave{display: flex; justify-content: center; margin-top: 25%;}
.introduce .intoduceIns .art_landing .wave img{width: 60%;}
.introduce .intoduceIns .art_landing.art_right .mountain{ left: -120px; right: inherit;}
.introduce .intoduceIns .logo_landing{width: 25%;}
.introduce .intoduceIns .logo_landing .divider{width: 130px; margin: 20px auto}
.introduce .intoduceIns .logo_landing .text_landing{text-align: center; margin-bottom: 40px;}
.introduce .intoduceIns .logo_landing .text_landing.connected{margin-bottom: 90px;}

.introduce .intoduceIns .logo_landing .btn-mint{display: flex; justify-content: center; flex-direction: column; align-items: center;}
.introduce .intoduceIns .logo_landing .btn-mint button{position: relative; padding: 10px; background: #96b4ad; width: 240px; text-align: center; border: 0; border-radius: 5px; color: #414042;}
.introduce .intoduceIns .logo_landing .btn-mint button::before{content: ''; background: url('../img/web/ic-flag-orange.png') top center no-repeat; background-size: 100%; width: 45px; position: absolute; left: -4px; top: 0; bottom: 0;}
.introduce .intoduceIns .logo_landing .btn-mint button::after{content: ''; background: url('../img/web/ic-flag-black.png') top center no-repeat; background-size: 100%; width: 45px; position: absolute; right: -4px; top: 0; bottom: 0;}
.introduce .intoduceIns .logo_landing .btn-mint button:hover{background: #fff;}
.introduce .intoduceIns .logo_landing .btn-mint .note{ text-align: center; opacity: 1; font-size: 22px; margin-bottom: 20px; color: #fff; /* -webkit-animation-name: blinker; -webkit-animation-duration: 0.8s; -webkit-animation-iteration-count:infinite; -webkit-animation-timing-function:ease-in-out; -webkit-animation-direction: alternate; */ }
.introduce .intoduceIns .logo_landing .btn-mint .notes{font-size: 19px; color: #fff;}
.introduce .intoduceIns .logo_landing .btn-mint .notes .countDown{font-size: 30px; margin-top: 10px; text-align: center;}
.introduce .intoduceIns .logo_landing .btn-mint .notes .countDown_desc{font-size: 14px; margin-top: 10px; text-align: center;}

/* btn mint stake v1 */
.btn-stakev1{display: flex; justify-content: center; flex-direction: column; align-items: center;}
.btn-stakev1 button{position: relative; padding: 10px; background: #96b4ad; width: 160px; text-align: center; border: 0; border-radius: 5px; color: #414042;}
.btn-stakev1 button::before{content: ''; background: url('../img/web/ic-flag-orange.png') top center no-repeat; background-size: 100%; width: 45px; position: absolute; left: -4px; top: 0; bottom: 0;}
/* .btn-stakev1 button::after{content: ''; background: url('../img/web/ic-flag-black.png') top center no-repeat; background-size: 100%; width: 45px; position: absolute; right: -4px; top: 0; bottom: 0;} */
.btn-stakev1 button:hover{background: #fff;}
.btn-stakev1 .note{ text-align: center; opacity: 1; font-size: 22px; margin-bottom: 20px; color: #fff; /* -webkit-animation-name: blinker; -webkit-animation-duration: 0.8s; -webkit-animation-iteration-count:infinite; -webkit-animation-timing-function:ease-in-out; -webkit-animation-direction: alternate; */ }
.btn-stakev1 .notes{font-size: 19px; color: #fff;}
.btn-stakev1 .notes .countDown{font-size: 30px; margin-top: 10px; text-align: center;}
.btn-stakev1 .notes .countDown_desc{font-size: 14px; margin-top: 10px; text-align: center;}

/* btn mint stake v2 */
.btn-stakev2{display: flex; justify-content: center; flex-direction: column; align-items: center;}
.btn-stakev2 button{position: relative; padding: 10px; background: #7fa199; width: 160px; text-align: center; border: 0; border-radius: 5px; color: #414042;}
/* .btn-stakev2 button::before{content: ''; background: url('../img/web/ic-flag-orange.png') top center no-repeat; background-size: 100%; width: 45px; position: absolute; left: -4px; top: 0; bottom: 0;} */
.btn-stakev2 button::after{content: ''; background: url('../img/web/ic-flag-black.png') top center no-repeat; background-size: 100%; width: 45px; position: absolute; right: -4px; top: 0; bottom: 0;}
.btn-stakev2 button:hover{background: #fff;}
.btn-stakev2 .note{ text-align: center; opacity: 1; font-size: 22px; margin-bottom: 20px; color: #fff; /* -webkit-animation-name: blinker; -webkit-animation-duration: 0.8s; -webkit-animation-iteration-count:infinite; -webkit-animation-timing-function:ease-in-out; -webkit-animation-direction: alternate; */ }
.btn-stakev2 .notes{font-size: 19px; color: #fff;}
.btn-stakev2 .notes .countDown{font-size: 30px; margin-top: 10px; text-align: center;}
.btn-stakev2 .notes .countDown_desc{font-size: 14px; margin-top: 10px; text-align: center;}

/* btn mint stake v1 di halaman staking */
.btn-stakev11{display: flex; justify-content: center; flex-direction: column; align-items: center;}
.btn-stakev11 button{position: relative; padding: 4px; background: #96b4ad; width: 160px; text-align: center; border: 0; border-radius: 0px; color: #414042;}
.btn-stakev11 button::before{content: ''; background: url('../img/web/ic-flag-orange.png') top center no-repeat; background-size: 100%; width: 45px; position: absolute; left: -4px; top: 0; bottom: 0;}
/* .btn-stakev11 button::after{content: ''; background: url('../img/web/ic-flag-black.png') top center no-repeat; background-size: 100%; width: 45px; position: absolute; right: -4px; top: 0; bottom: 0;} */
.btn-stakev11 button:hover{background: #fff;}
.btn-stakev11 .note{ text-align: center; opacity: 1; font-size: 22px; margin-bottom: 20px; color: #fff; /* -webkit-animation-name: blinker; -webkit-animation-duration: 0.8s; -webkit-animation-iteration-count:infinite; -webkit-animation-timing-function:ease-in-out; -webkit-animation-direction: alternate; */ }
.btn-stakev11 .notes{font-size: 19px; color: #fff;}
.btn-stakev11 .notes .countDown{font-size: 30px; margin-top: 10px; text-align: center;}
.btn-stakev11 .notes .countDown_desc{font-size: 14px; margin-top: 10px; text-align: center;}

/* btn mint stake v2 di halaman staking */
.btn-stakev22{display: flex; justify-content: center; flex-direction: column; align-items: center;}
.btn-stakev22 button{position: relative; padding: 4px; background: #7fa199; width: 160px; text-align: center; border: 0; border-radius: 0px; color: #414042;}
/* .btn-stakev22 button::before{content: ''; background: url('../img/web/ic-flag-orange.png') top center no-repeat; background-size: 100%; width: 45px; position: absolute; left: -4px; top: 0; bottom: 0;} */
.btn-stakev22 button::after{content: ''; background: url('../img/web/ic-flag-black.png') top center no-repeat; background-size: 100%; width: 45px; position: absolute; right: -4px; top: 0; bottom: 0;}
.btn-stakev22 button:hover{background: #fff;}
.btn-stakev22 .note{ text-align: center; opacity: 1; font-size: 22px; margin-bottom: 20px; color: #fff; /* -webkit-animation-name: blinker; -webkit-animation-duration: 0.8s; -webkit-animation-iteration-count:infinite; -webkit-animation-timing-function:ease-in-out; -webkit-animation-direction: alternate; */ }
.btn-stakev22 .notes{font-size: 19px; color: #fff;}
.btn-stakev22 .notes .countDown{font-size: 30px; margin-top: 10px; text-align: center;}
.btn-stakev22 .notes .countDown_desc{font-size: 14px; margin-top: 10px; text-align: center;}

/* about */
.about{overflow: hidden; background: #243321; display: flex; flex-direction: column; justify-content: center;}
.about ul.listTeam{display: flex; align-items: center; justify-content: center; flex-flow: row wrap; width: 200%; animation: moveSlideshow 20s linear infinite; position: relative; }
.about ul.listTeam li{padding: 0 5px; width: 20%; flex: 0 0 10%;}
.about ul.listTeam.right{left: -100%; animation: moveSlideshowRight 25s linear infinite;}
.about .about_text{text-align: center; font-size: 16px; color: #fff; display: flex; justify-content: center; margin: 20px 0;}
.about .about_text span{width: 490px;}

/* roadmap */
.roadmap{display: flex; align-items: center; justify-content: center; background: url('../img//web/bg-home.png') bottom center; background-repeat: repeat-x;}
.roadmap .roadmapIns{display: flex; justify-content: space-between;}
.roadmap .roadmapIns .listPhase.left{width: 27%; text-align: right;}
.roadmap .roadmapIns .listPhase.right{display: flex; align-items: center;}
.roadmap .roadmapIns .listPhase .phase1{margin-bottom: 37%;}
.roadmap .roadmapIns .listPhase .phase.left{text-align: right;}
.roadmap .roadmapIns .listPhase .phase span{display: block;}
.roadmap .roadmapIns .listPhase .phase .ttl{font-size: 22px; margin-bottom: 15px; font-weight: bold;}
.roadmap .roadmapIns .listPhase .phase .desc{font-size: 15px;}
.roadmap .roadmapIns .img{width: 45%; padding: 0 20px;}

/* utility */
.utility{background: #d5d0ca; padding-top: 2% !important;}
.utility .title{display: flex; flex-direction: row; justify-content: center; margin: 20px 0 10px 0; align-content: center;}
.utility .title .ic{width: 100px;}
.utility .title .txt{font-size: 40px; display: flex; align-self: center;}
.utility .desc{text-align: center; font-size: 16px; margin-bottom: 40px; color: #414042;}
.utility .faqIns ul{padding-left: 20px;}
.utility .faqIns ul li{list-style: disc; margin-bottom: 10px;}

/* createor_space */
.creator_space{background: #e0d8d0; padding-top: 2%; padding-bottom: 6%;  margin-top: 40px;}
.creator_space .content{display: flex; flex-direction: row; justify-content: space-between; align-items: flex-start; margin-top: 40px;}
.creator_space .content .list{width: 30%; position: relative;}
.creator_space .content .list .img{position: relative;}
.creator_space .content .list .img::after{content: ''; position: absolute; right: -32%; top: 42%; background: url('../img/web/utility/arrow-right.png') top center no-repeat; background-size: 100%; width: 50%; height: 50px;}
.creator_space .content .list:last-child .img::after{content: none;}
.creator_space .content .list .txt{text-align: center;}

/* fuzzClan */
.fuzzClan{background: #96b4ad; display: flex; flex-direction: column; justify-content: space-between; height: auto; }
.fuzzClan .title{text-align: center; font-size: 40px; margin-top: 20px; margin-bottom: 40px; font-weight: bold;}

/* faq */
.faq{background: #516946; min-height: 83vh !important;}
.faq .ttl{text-align: center; font-size: 32px; color: #fff; margin-bottom: 60px;}

/* footer */
.footer{background: #243321; padding: 20px 0;}

.network{position: fixed; left: 0; right: 0; top: 75px; z-index: 99; background: #c04949; color: #fff; font-size: 14px; padding: 10px 0; text-align: center;}
.network .ic_close{ position: absolute; top: 0; right: 10px; bottom: 0; background: 0; border: 0; color: #fff; font-size: 20px; }


/* introduceStack  */

.introduceStack{display: flex; padding: 4% 0 !important; background: #000000; background-repeat: repeat-x; background-size: contain;}
.introduceStack .title{text-align: center; font-size: 35px; color: #fff;  margin-bottom: 40px; font-weight: bold;}

/* .introduceStack{display: flex; padding: 8% 0 !important; background: url('../img//web/bg-home.png') bottom center; background-repeat: repeat-x; background-size: contain;} */
.introduceStack .info-btn{display: flex; flex-direction: row; justify-content: space-between; align-items: center;}
.introduceStack .info-btn .info{display: flex; flex-direction: row;}
.introduceStack .info-btn .info li{margin-right: 40px; list-style: disc; color: #fff;}
.introduceStack .info-btn .info li .listInfo{color:#fff}
.introduceStack .info-btn .info li:first-child{list-style: none;}
.introduceStack .info-btn .btn{display: flex; flex-direction: row;}
.introduceStack .info-btn .btn button{font-size: 14px; margin-left: 20px; background: #fff; border: 0;  padding: 5px 30px; color: #414042}
/* 
.introduceStack .btn-staking{display: flex; flex-direction: row; justify-content: center; margin: 30px 0;}
.introduceStack .btn-staking button{ background: 0; border: 0; width: 180px;} */

.introduceStack .introduceStackOut{position: relative; display: flex; flex-direction: column; background: #BEBAB1; padding: 20px; border-radius: 10px; margin-top: 50px;}
.introduceStack .introduceStackIns{display: flex; flex-direction: row; justify-content: space-between;}
.introduceStack .introduceStackIns .stack-ins{ border-right: 1px solid #a8a8aa; padding-right: 30px; margin-right: 30px; width: 50%;}
.introduceStack .introduceStackIns .stack-ins:last-child{border-right: 0; padding-right: 0; margin-right: 0;}
.introduceStack .introduceStackIns .stack-ins .ttl_btn {display: flex; flex-direction: row; justify-content: space-between; align-items: center;}
.introduceStack .introduceStackIns .stack-ins .ttl_btn .title{font-size: 23px; color: #000; font-weight: bold;}
.introduceStack .introduceStackIns .stack-ins .ttl_btn .btn_grid{background: #fff; color: #CD7555; border-radius: 3px; margin-left: 10px; padding: 5px;}
.introduceStack .introduceStackIns .stack-ins .ttl_btn .btn_grid.active{background: #CD7555; color: #fff; }

.introduceStack .introduceStackIns .stack-ins .loadingBox{ display: flex; align-items: center; justify-content: center; margin-top: 20px; height: 60vh; overflow: scroll; background: #f5e9e9; padding: 10px 20px; border: 1px solid #a8a8aa; }
.introduceStack .introduceStackIns .stack-ins ul.list-stack{display: flex; flex-wrap: wrap; justify-content: space-between; margin-top: 20px; height: 60vh; overflow: scroll; background:#FFFCEB; padding: 10px 20px; border: 2px solid #000000;}
.introduceStack .introduceStackIns .stack-ins ul.list-stack li{width: 48%; margin: 10px 0}
.introduceStack .introduceStackIns .stack-ins ul.list-stack li.active button{border: 10px solid #CD7555; }
.introduceStack .introduceStackIns .stack-ins ul.list-stack li button{position: relative; border: 10px solid transparent; padding: 0; border-radius: 0;}
/* .introduceStack .introduceStackIns .stack-ins ul.list-stack li.default button{position: relative; border:0; padding: 0; border-radius: 0;} */

.introduceStack .introduceStackIns .stack-ins ul.list-stack li button ._id{position: absolute; bottom: 0; right: 0; font-size: 14px; color: #fff; background: #CD7555; padding: 0 10px; border-top-left-radius: 5px;}
.introduceStack .introduceStackIns .stack-ins ul.list-stack li.active button ._id{ background: #CD7555; }

.introduceStack .introduceStackIns .stack-ins ul.list-stack._list{height: 62.5vh; flex-direction: column; flex-wrap: nowrap; justify-content: inherit; padding: 0;}
.introduceStack .introduceStackIns .stack-ins ul.list-stack._list li{width: 100%; margin: 0;}
.introduceStack .introduceStackIns .stack-ins ul.list-stack._list li button {border: 0; background: #fff; width: 100%; display: block; border-bottom: 1px solid #0000; padding: 5px 0;}
.introduceStack .introduceStackIns .stack-ins ul.list-stack._list li.active button{background: #CD7555;}
.introduceStack .introduceStackIns .stack-ins ul.list-stack._list li.default .img{display: none;}
.introduceStack .introduceStackIns .stack-ins ul.list-stack._list li button .img{display: none;}
.introduceStack .introduceStackIns .stack-ins ul.list-stack._list li button ._id{font-size: 14px; position: relative; border-top-left-radius: 0; background: 0; color: #414042; text-align: left; font-weight: bold;}
.introduceStack .introduceStackIns .stack-ins ul.list-stack._list li.active button ._id{color: #fff}

.introduceStack .introduceStackIns .stack-ins .btn_submit{display: flex; justify-content: center; margin-top: 30px;}
.introduceStack .introduceStackIns .stack-ins .btn_submit span{width: 200px; height: 50px;}
.introduceStack .introduceStackIns .stack-ins .btn_submit span.Mui-disabled{opacity: 0.5;}


.introduceStack.eContract{align-items: center; padding: 0 !important}
.introduceStack.eContract .introduceStackOut{margin-top: 0 !important; min-height: 70vh; border-radius: 0rem; justify-content: center;}
.introduceStack.eContract .introduceStackOut .img {width: 50%; color: #000;}
.introduceStack.eContract .introduceStackOut .btn_enable{ display: flex; flex-direction: row; justify-content: center; }
.introduceStack.eContract .introduceStackOut .btn_enable span{margin: 0 20px; height: 30px; }
.introduceStack.eContract .introduceStackOut .btn_enable .ic_btn{width: 80px;}

.loading_modal .icon_{width: 100px; margin: 0 auto; }
.loading_modal .ic_loading img{animation: loadingRotate 0.8s linear infinite;}
.loading_modal .text{font-weight: bold; color: #516946}

@keyframes moveSlideshow {
  0% {transform: translateX(0);}
  100% {transform: translateX(-50%);}
}

@keyframes moveSlideshowRight {
  0% {transform: translateX(0);}
  100% {transform: translateX(50%);}
}

@keyframes loadingRotate {
  0% {transform: rotate(0);}
  100% {transform: rotate(180deg);}
}

@keyframes blinker {
  from {opacity: 1.0;}
  to {opacity: 0.2;}
}

.img-title-shadow {
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0.00) 0%, rgba(0,0,0,0.6) 100%);
}

.btn-wallet-orange{
  background-color: '#ffffff';
}

.bg-garis-x{background: url('../img/web/v5/bgx.png') top center no-repeat; background-size: 100%;}
.foxpandaimg{max-width: 300px;}
.playimg{max-width: 160px; cursor: pointer;}
.moonIslandLabsImg{max-width: 320px;}
.btnReadFuzz{background-color: #414141;}
.windowMoonImg{max-width: 100%;}

/* home2 */
/* .home2{
  width: 100vw;
  height: 90vh;
  display: flex;
} */


/* hover layer about us */
.overlay-aboutus {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #000000;
}

.container-aboutus:hover .overlay-aboutus {
  opacity: .7;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

/* hover layer roadmap */
.overlay-roadmap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #000000;
}

.container-roadmap:hover .overlay-roadmap {
  opacity: .7;
}
/* hover layer fuzzclan */
.overlay-fuzzclan {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #000000;
}

.container-fuzzclan:hover .overlay-fuzzclan {
  opacity: .7;
}

/* hover layer utility */

.overlay-utility {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #000000;
}

.container-utility:hover .overlay-utility {
  opacity: .7;
}

/* hover layer rarity */
.overlay-rarity {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #000000;
}

.container-rarity:hover .overlay-rarity {
  opacity: .7;
}

.bg-about .img-about {height: 70%; width: auto; padding-top: 13%;}
/* Astro About */
.about2 {background: url('../img/bg-aboutus.jpg') center center no-repeat; background-size:cover; display: flex; flex-direction: row; justify-content: space-around; margin-top: 98px; height: calc(100vh - 98px)!important;}
.about2 .close {justify-content: end; align-items: end;}
.about2 .aboutIns{display: flex; justify-content: center;}
.about2 .aboutIns .imgAbout {width: 70%; margin-top: 20px; margin-left: 20%;}
.about2 .about_text{justify-content: center; align-items: center; text-align: left; margin-left: 60px; margin-top: 15%;}
.about2 .about_text span{width: 350px;}
.about2 .about_text .bold{text-align: left; font-size: 30px; font-weight: 800; color: #fff; display: flex; justify-content: left; margin: 20px 0; margin-top: 50px; margin-bottom: 40px;}
.about2 .about_text .bold2{text-align: left; font-size: 15px; font-weight: 800; color: #fff; display: flex; justify-content: left; margin: 20px 0; margin-top: 50px; margin-bottom: 40px;}
.about2 .about_text .text2{text-align: left; font-size: 15px; font-weight: 100; color: #fff; display: flex; justify-content: center; margin: 20px 0; }


/* Astro Roadmap */
.roadmap2 {display: flex; align-items: center; justify-content: center; background: url('../img/bg-roadmap.jpg') center center no-repeat; background-size:cover;  margin-top: 98px; height: 100vh;}
.roadmap2 .content {display: flex; justify-content: center; }
.roadmap2 .content .imgRoadmap {width: 70%; position: relative; }
.roadmap2 .title-text {font-size: 12px; font-weight: bold;}
.roadmap2 .phase1 { color: #fff; position: absolute; top: 30%; left: 26%; width: 30%; }
.roadmap2 .text1 {font-size: 10px;}
.roadmap2 .phase2 { color: #fff; position: absolute; top: 87%; left: 49%; width: 30%;}
.roadmap2 .text2 {font-size: 10px;}
.roadmap2 .phase3 {color: #fff; position: absolute; top: 30%; right: 14%; width: 10%;}
.roadmap2 .text3 {font-size: 10px;}
.roadmap2 .title {position: relative; text-align: center; font-size: 30px;  margin-bottom: 20px; font-weight: bold; color: #ffffff;}
.roadmap2 .close{display: flex; justify-content: end; align-items: end;}

/* Astro Utility */

.utility2 {display: flex; align-items: center; justify-content: center; background: #d5d0ca; margin-top: 6%;}
.utility2 .title {display: flex; flex-direction: row; justify-content: center; align-content: center;}
.utility2 .title .ic{width: 100px;}
.utility2 .title .txt{font-size: 40px; display: flex; align-self: center;}
.utility2 .desc{text-align: center; font-size: 16px; margin-bottom: 20px; color: #414042;}
.utility2 .faqIns ul{padding-left: 20px;}
.utility2 .faqIns ul li{list-style: disc; }
.utility2 .close {display: flex; justify-content: end; align-items: end;}

/* Astro Team */
.fuzzClan2 {background: #7bb9f6; display: flex; flex-direction: column; justify-content: space-between; align-items: center; margin-top: 98px; height: 100vh;}
.fuzzClan2 .title{text-align: center; font-size: 30px; margin-top: 50px; padding-bottom: 20px;  font-weight: bold;}
.fuzzClan2 .content{display: flex; justify-content: center;}
.fuzzClan2 .content .imgClan{width: 30%;}
.fuzzClan2 .close {display: flex; justify-content: end; align-items: end;}
.fuzzClan2 .img {width: 100%;}

.content-hide {position:absolute; display:block; top: 0; bottom: 0; left: 0; right: 0; transition: 0.2s all; opacity: 1; pointer-events: auto; z-index: 3; }
.content-hide .close {
  position: absolute;
  display: block;
  /* margin-top: 98px; */
  margin-left: 95%;
  right: 0;
  transition: 0.2s all;
  opacity: 1;
  pointer-events: auto;
  z-index: 3;
  bottom: calc(100vh - 157px)!important;
}
.hide-content {opacity: 0; pointer-events: none; z-index: -1;}
.close {position:absolute; display:block; margin-top: 50%; bottom: 0; left: 0; right: 0; transition: 0.2s all; opacity: 1; pointer-events: auto; z-index: 3;}

.content-hide2 {position:absolute; display:block; top: 0; bottom: 0; left: 0; right: 0; transition: 0.2s all; opacity: 1; pointer-events: auto; z-index: 4;}
.content-hide2-none {display: none;}
.content-hide2 .close2{
  position: absolute;
  display: block;
  /* margin-top: 98px; */
  margin-left: 95%;
  right: 0;
  transition: 0.2s all;
  opacity: 1;
  pointer-events: auto;
  z-index: 3;
  bottom: calc(100vh - 157px)!important;
}
.hide-content2 {opacity: 0; pointer-events: none; z-index: -1;}
.close2 {position:absolute; display:block; margin-top: 50%; bottom: 0; left: 0; right: 0; transition: 0.2s all; opacity: 1; pointer-events: auto; z-index: 3;}


.profileFuzzy {display: flex; top: 10px;}
.dashboard {overflow: hidden; }