.dd-menu-ijo > li > a {
  text-decoration: none;
  color: #ffffff;
  /* color: #6a7f60; */
}

.dd-menu-ijo > li > a:hover {
  color: #ffffff;
  /* color: #6a7f60; */
}

/* Dropdown-ijo */

.dropdown-ijo {
  display: inline-block;
  position: relative;
}

.dd-button-ijo {
  display: inline-block;
  border: 2px solid gray;
  /* border-radius: 4px; */
  padding: 8px 30px 8px 20px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 15px;
}

.dd-button-ijo:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #6a7f60;
}

.dd-button-ijo:hover {
}

.dd-input-ijo {
  display: none;
}

.dd-menu-ijo {
  position: absolute;
  top: 100%;
  border: 1px solid #ffffff;
  /* border: 1px solid #6a7f60; */
  /* border-radius: 4px; */
  padding: 0;
  margin: 2px 0 0 0;
  /* box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1); */
  background-color: #000000;
  list-style-type: none;
  z-index: 2;
  width: 100%;
  font-size: 14px;
}

.dd-input-ijo + .dd-menu-ijo {
  display: none;
}

.dd-input-ijo:checked + .dd-menu-ijo {
  display: block;
}

.dd-menu-ijo li {
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;
}

.dd-menu-ijo li:hover {
  /* background-color: #f6f6f6; */
}

.dd-menu-ijo li a {
  display: block;
  margin: -10px -20px;
  padding: 10px 20px;
}

.dd-menu-ijo li.divider {
  padding: 0;
  border-bottom: 1px solid #cccccc;
}
