.img-logo-fuzzshop {
  margin-top: 30px;
  max-width: 40vh;
  margin-left: 40px;
}

#sortBy{
  padding: 8.5px 14px;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root, 
.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input, 
#sortBy, 
label.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root, 	.MuiPagination-text{
  color: #ffffff!important
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
  background-color: #FFD62C!important
}

@media screen and (max-width: 425px) {
  .img-logo-fuzzshop {
    margin-top: 60px;
    width: 100%;
    max-width: 90%;
  }
}
